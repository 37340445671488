/* You can add global styles to this file, and also import other style files */
@import url('@scania/tegel/dist/tegel/tegel.css');

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

:root {
  --color-blue: #041E42;
  --color-white: #FAFAFA;
  --color-light-grey: #C8C9C7;
  --color-medium-grey: #97999B;
  --color-dark-grey: #53565A;
  --color-black: #000000;
  --color-pale-green: #94A596;
  --color-green: #2C5234;
  --color-beige: #CEB888;
  --color-orange: #E35205;
  --color-red: #D6001C;
  --color-information: #4A89F3;
  --color-positive: #1DAB8B;
  --color-warning: #F1C21B;
  --color-negative: #FF2340;
  --color-ruby-red: #810422;
  --color-glacier-blue: #6191B4;
  --color-gradiente-padrao-horiz: linear-gradient(90deg, rgb(17, 83, 158) 5%, rgb(97, 147, 194) 49%, rgb(35, 103, 134)75%) !important;
  --color-gradiente-padrao-vert: linear-gradient(180deg, rgb(17, 83, 158) 5%, rgb(97, 147, 194) 49%, rgb(35, 103, 134)75%) !important;
}

.bgScania-blue {
  background-color: var(--color-blue) !important;
  color: var(--color-white) !important;
}

.cor-gradiente-padraoVertical {
  background: var(--color-gradiente-padrao-vert) !important;
}

.cor-gradiente-padraoHorizontal {
  background: var(--color-gradiente-padrao-horiz) !important;
}

.roboto100 {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
  font-size: 1.1rem;
}

.roboto200 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.20em;
}

.roboto200Bold {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1.20em;
}


.roboto300 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.3rem;
}


.roboto400 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.4rem;
}


.roboto500 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.5rem;
}

.roboto500Bold {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1.5rem;
}


.roboto500-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 1.5rem;
}

.roboto600 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.6rem;
}

.roboto700 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.7rem;
}


.upperCase {
  text-transform: uppercase;
}

.color-information {
  color: var(--color-information) !important;
}

.color-warning {
  color: var(--color-warning) !important;
}

.color-danger {
  color: var(--color-red) !important;

}

.color-glacier {
  color: var(--color-glacier-blue) !important;

}

.bgcolor-medium-gray {
  background-color: var(--color-medium-grey) !important;
}

.bgcolor-information {
  background: var(--color-information) !important;
}

.bgcolor-warning {
  background: var(--color-warning) !important;
}

.bgcolor-danger {
  background: var(--color-red) !important;

}

.bgcolor-glacier {
  background: var(--color-glacier-blue) !important;

}

.bgcolor-grid {
  background-color: var(--color-pale-green) !important;
}

.cursorMao {
  cursor: pointer;
}

.sombra {
  filter: none;
  transition: filter 0.3s ease;
}

.sombra:hover {
  filter: drop-shadow(0 0 5px #000)
}

.sombra-branca {
  filter: drop-shadow(0 0 5px #f5f5f5);
}

.sombra-amarela {
  /* filter: drop-shadow(0 0 5px #f3d423); */
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 1),
    inset 0px 0px 0px rgba(192, 174, 8, 0.95),
    inset -3px -3px 5px rgba(241, 202, 25, 0.95);
}

.folha {
  border-radius: 20px 0px !important;
}